import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Login from './login'
import Lander from './lander'
import WeekFoodsAi from './../foodOrderEstimation/weekFoodsAi'

const LanderPage = () => {
  const { loggedIn } = useSelector(state => state.user)
  const {orgs} = useSelector(
    state => state
  )
  const { t } = useTranslation()

  if (loggedIn) {
    if (orgs && orgs.length > 0 && orgs[0].name) {
      if (orgs[0].name.toLowerCase() === 'pirkanmaan voimia') {
        return <WeekFoodsAi />
      }
    } else {
      return <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
    }
  }
  const renderComponent = loggedIn ? <Lander /> : <Login />

  return renderComponent
}

export default LanderPage
