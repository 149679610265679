import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './index.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next';
import HukkaSelect from '../hukkaSelect'
import DateRow from './dateRow'
import Modal from '../modal'
import { get, post, MEALSERVINGS_URL, MEALSERVINGLOGS_URL } from '../../utility/api'
import {
  renewMealServings,
  renewMealServingLogs
} from '../../actions'
import ErrorMessage from '../errorMessage'
import SuccessMessage from '../successMessage'

const DATE_FORMAT = 'DD.MM.'

const Menu = () => {
  const { mealServings, mealServingLogs, restaurants, mealTypes, wasteTypes, hukkaProfiles } = useSelector(
    state => state
  )
  const { id } = useSelector(
    state => state.user
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedRestaurants, setSelectedRestaurants] = useState(null)
  const [weekNumber, setWeekNumber] = useState(moment().isoWeek())
  const [yearNumber, setYearNumber] = useState(moment().isoWeekYear())
  const [copyWeekModalOpen, setCopyWeekModalOpen] = useState(false)
  const [textModalOpen, setTextModalOpen] = useState(false)
  const [sourceYear, setSourceYear] = useState(false)
  const [sourceWeek, setSourceWeek] = useState(false)
  const [targetYear, setTargetYear] = useState(false)
  const [targetWeek, setTargetWeek] = useState(false)
  const [copyWeekProcessing, setCopyWeekProcessing] = useState(false)
  const [copyWeekError, setCopyWeekError] = useState(false)
  const [copyWeekSuccess, setCopyWeekSuccess] = useState(false)
  const [errors, setErrors] = useState(null)
  const [fetchedServingsMon, setFetchedServingsMon] = useState('')
  const monday = moment()
    .isoWeek(weekNumber)
    .isoWeekYear(yearNumber)
    .startOf('week')

  const getMealServings = async (from, to) => {
    let payload = {
      from: from,
      to: to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      dispatch(renewMealServings(resp.data))
      const resp2 = await get(MEALSERVINGLOGS_URL, payload)

      if (resp2.status === 200) {
        dispatch(renewMealServingLogs(resp2.data))
        setFetchedServingsMon(from)
      } else {
        setErrors(resp.data)
      }
    } else {
      setErrors(resp.data)
    }
  }

  const dateRows = () => [...Array(7).keys()].map(i => {
    const thisDay = monday.clone().add(i, 'days')

    const mealServingsForDateAndRestaurants = mealServings
    .filter(ms => moment(ms.servingDatetime).isSame(thisDay, 'date'))
    .filter(ms =>
      selectedRestaurants.map(r => r.value).includes(ms.restaurant)
    )
    .map(ms => {
      const restaurant = restaurants.find(r => r.id === ms.restaurant)
      const restaurantName = restaurant
        ? restaurant.name
        : t('Ravintolaa ei löytynyt')

      return { ...ms, restaurantName }
    })

    const mealServingsIds = mealServingsForDateAndRestaurants.map(m => m.id)
    const mealServingLogsForMealServing = mealServingLogs.filter(msl => mealServingsIds.includes(msl.mealServing))

    return (
      <DateRow
        key={thisDay.format(DATE_FORMAT)}
        date={thisDay}
        mealServings={mealServingsForDateAndRestaurants}
        mealServingLogs={mealServingLogsForMealServing}
        mealTypes={mealTypes}
        selectedRestaurants={selectedRestaurants}
        restaurants={restaurants}
        monday={monday}
      />
    )
  })

  const menuAsText = () => {
    let html = [];

    if (selectedRestaurants && selectedRestaurants.length > 0) {
      selectedRestaurants.forEach(sr => {
        if (selectedRestaurants.length > 1) {
          html.push(<p style={{textTransform: 'uppercase'}}><b>{restaurants.find(r => r.id === sr.value).name}</b></p>)
        }
        for (let i = 0; i < 5; i++) {
          const thisDay = monday.clone().add(i, 'days')
      
          const mealServingsForDateAndRestaurants = mealServings
          .filter(ms => moment(ms.servingDatetime).isSame(thisDay, 'date') && ms.restaurant === sr.value)
          .map(ms => {
            const restaurant = restaurants.find(r => r.id === sr.value)
            const restaurantName = restaurant
              ? restaurant.name
              : ''
      
            return { ...ms, restaurantName }
          })
      
          const mealServingsIds = mealServingsForDateAndRestaurants.map(m => m.id)
          const mealServingLogsForMealServing = mealServingLogs.filter(msl => mealServingsIds.includes(msl.mealServing))

          html.push(<br />)
          html.push(<p><b>{moment(thisDay).format('dddd')}</b></p>)
          mealServingLogsForMealServing.forEach(log => {
            html.push(<p>{log.foodName}</p>)
          })
        }
        html.push(<br />)
        html.push(<br />)
        html.push(<br />)
      })
    }

    return (
      <div>{html}</div>
    )
  }

  const removeDublicatesAndNonExist = (arr) => {
    let uniqueIds = []
    let uniqueExist = []
    arr.forEach(function (a) {
      if (uniqueIds.indexOf(a.value) === -1) {
        uniqueIds.push(a.value)
        if (restaurants.find(r => r.id === a.value)) {
          uniqueExist.push(a)
        }
      }
    })
    return uniqueExist
  }

  const restaurantGroupOptions = hukkaProfiles && hukkaProfiles.find(hp => hp.id === id).data &&
  hukkaProfiles.find(hp => hp.id === id).data.restaurantGroups
    .map(rg => ({
      value: rg.restaurants,
      label: rg.name,
    }))

  const restaurantGroupSelect = (<>
    <HukkaSelect
      options={restaurantGroupOptions ? restaurantGroupOptions : []}
      value={''}
      placeholder={t('Valitse ravintolaryhmä')}
      onChange={e => {
        let arr = []
        if (selectedRestaurants !== null) {
          arr = removeDublicatesAndNonExist(selectedRestaurants.concat(e.value))
        } else {
          arr = removeDublicatesAndNonExist(e.value)
        }
        getMealServings(monday.clone().format('YYYY-MM-DD'), monday.clone().add(6, 'days').format('YYYY-MM-DD'))
        setSelectedRestaurants(arr)
      }}
    />
    <i style={{textAlign: 'left', display: 'block'}}>{t('Lisää/Muokkaa ryhmiä')} <a href="/profile">{t('profiilisivulla')}</a></i>
  </>)

  const restaurantOptions = restaurants.slice()
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  })
  .map(r => ({
    value: r.id,
    label: r.name,
  }))
  .concat([
    {
      value: 'all',
      label: t('Lisää kaikki ravintolat'),
    },
  ])

  const restaurantSelect = (
    <>
      {restaurantGroupSelect}
      <div style={{textAlign: 'left', margin: '10px 0'}}>
        <HukkaSelect
          options={restaurantOptions}
          value={selectedRestaurants}
          isMulti
          placeholder={t('Valitse ravintola')}
          onChange={e => {
            getMealServings(monday.clone().format('YYYY-MM-DD'), monday.clone().add(6, 'days').format('YYYY-MM-DD'))
            if (e && e.find(r => r.value === 'all')) {
              setSelectedRestaurants(
                restaurantOptions.filter(ro => ro.value !== 'all')
              )
            } else {
              setSelectedRestaurants(e)
            }
          }}
        />
      </div>
    </>
  )

  const changeWeekNumber = delta => {
    setCopyWeekSuccess(false)
    setCopyWeekError(false)
    let thisWeek = moment().isoWeekYear(yearNumber).isoWeek(weekNumber)
    let changedWeekNumber = thisWeek.add(delta, 'isoWeek').isoWeek()
    let previousWeekNumber = thisWeek.add((delta * -1), 'isoWeek').isoWeek()
    let changedYearNumber = yearNumber
    if ((previousWeekNumber + 50) < changedWeekNumber) {
      changedYearNumber = yearNumber - 1
      setYearNumber(changedYearNumber)
    } else if ((previousWeekNumber - 50) > changedWeekNumber) {
      changedYearNumber = yearNumber + 1
      setYearNumber(changedYearNumber)
    }
    setWeekNumber(changedWeekNumber)
    const newMonday = moment().isoWeekYear(changedYearNumber).isoWeek(changedWeekNumber).startOf('isoWeek')
    getMealServings(newMonday.clone().format('YYYY-MM-DD'), newMonday.clone().add(6, 'days').format('YYYY-MM-DD'))
  }

  const weekNavigator = (
    <div className="column is-full">
      <button
        type="button"
        className="button is-small is-primary"
        style={{ marginRight: '15px', minWidth: '125px' }}
        disabled={fetchedServingsMon !== moment(monday).format('YYYY-MM-DD')}
        onClick={() => {
          changeWeekNumber(-1)
        }}
      >
        <Trans>edellinen</Trans>
      </button>

      <button
        type="button"
        className="button is-small is-primary"
        style={{ marginRight: '15px', minWidth: '125px' }}
        disabled={fetchedServingsMon !== moment(monday).format('YYYY-MM-DD')}
        onClick={() => {
          const newMonday = moment().startOf('isoWeek')
          getMealServings(newMonday.clone().format('YYYY-MM-DD'), newMonday.clone().add(6, 'days').format('YYYY-MM-DD'))
          setWeekNumber(moment().isoWeek())
          setYearNumber(moment().year())
        }}
      >
        <Trans>Takaisin nykyiseen viikkoon</Trans>
      </button>

      <button
        type="button"
        className="button is-small is-primary"
        style={{ marginRight: '15px', minWidth: '125px' }}
        disabled={fetchedServingsMon !== moment(monday).format('YYYY-MM-DD')}
        onClick={() => {
          changeWeekNumber(1)
        }}
      >
        <Trans>seuraava</Trans>
      </button>
    </div>
  )

  const weeksInYear = (year) => {
    let weeksInYear = moment().year(year).isoWeeksInYear()
    let weeks = []
    for(let i = 0;i < weeksInYear;i++) {
      weeks.push({
        value: (i + 1).toString(),
        label: t('Viikko') + ' ' + (i + 1)
      })
    }
    return weeks
  }

  const postWeekMenu = async (servingArray) => {
    setCopyWeekSuccess(false)
    setCopyWeekError(false)
    let servings = []
    let servingLogs = []
    servingArray.forEach(sa => {
      sa.servings.forEach(sas => {
        servings.push({
          meal_type: sas.mealType,
          serving_datetime: sas.servingDatetime,
          restaurant: sas.restaurant
        })
        servingLogs.push(sas.logs)
      })
    })
    
    const resp = await post(MEALSERVINGS_URL, servings)

    if (resp.status === 201) {
      let servingArrayToPost = []
      let lineWasteId = wasteTypes.find(w => w.name === 'line').id
      resp.data.forEach((rd, rdIndex) => {
        servingLogs[rdIndex].forEach(sl => {
          if (sl['waste_type'] === lineWasteId && sl['food_name'] && sl['food_name'] !== '') {
            sl['meal_serving'] = rd.id
            servingArrayToPost.push(sl)
          }
        })
      })

      const logsResp = await post(MEALSERVINGLOGS_URL, servingArrayToPost)

      if (logsResp.status === 201) {
        getMealServings(monday.format('YYYY-MM-DD'), monday.clone().add(7, 'days').format('YYYY-MM-DD'))
        setCopyWeekSuccess(t('Menu kopioitu onnistuneesti'))
      } else {
        setCopyWeekError(t('Jokin meni vikaan'))
      }
    } else {
      setCopyWeekError(t('Jokin meni vikaan'))
    }
    setCopyWeekModalOpen(false)
    setCopyWeekProcessing(false)
  }

  const handleCopyWeekModalSubmit = async () => {
    let sourceWeekDay = moment().year(sourceYear).isoWeek(sourceWeek.value).startOf('isoWeek')
    let targetWeekDay = moment().year(targetYear).isoWeek(targetWeek.value).startOf('isoWeek')
    let payload = {
      from: moment().year(sourceYear).isoWeek(sourceWeek.value).startOf('isoWeek').format('YYYY-MM-DD'),
      to: moment().year(sourceYear).isoWeek(sourceWeek.value).endOf('isoWeek').format('YYYY-MM-DD')
    }
    const msResp = await get(MEALSERVINGS_URL, payload)
    const mslResp = await get(MEALSERVINGLOGS_URL, payload)
    let msData = null
    let mslData = null

    if (msResp.status === 200 && mslResp.status === 200) {
      msData = msResp.data
      mslData = mslResp.data
      let sourceDays = []
      let targetDays = []
      for (let i = 0; i < 7; i++) {
        sourceDays.push(moment(sourceWeekDay).format('YYYY-MM-DD'))
        targetDays.push(moment(targetWeekDay).format('YYYY-MM-DD'))
        sourceWeekDay = moment(sourceWeekDay).add(1, 'days')
        targetWeekDay = moment(targetWeekDay).add(1, 'days')
      }
      let restaurants = selectedRestaurants.map(sr => sr.value)
      let servings = []
      let selectedMs = msData.filter(f => restaurants.indexOf(f.restaurant) !== -1 && sourceDays.indexOf(moment(new Date(f.servingDatetime)).format('YYYY-MM-DD')) !== -1)
      let selectedMsIds = selectedMs.map(ms => ms.id)
      let selectedMslogs = mslData.filter(msl => selectedMsIds.indexOf(msl.meal_serving))

      sourceDays.forEach((sd, weekdayIndex) => {
        let servingArr = selectedMs.filter(sms => moment(new Date(sms.servingDatetime)).format('YYYY-MM-DD') === sd).map(sms => {
          let obj = {
            id: sms.id,
            mealType: sms.mealType,
            servingDatetime: new Date(targetDays[weekdayIndex] + ' ' + moment(new Date(sms.servingDatetime)).format('HH:mm:ss.SSS')),
            restaurant: sms.restaurant,
            logs: []
          }
          return obj
        })
        servingArr.forEach(serving => {
          serving.logs = selectedMslogs.filter(smsl => smsl.mealServing === serving.id).map(smsl => {
            return {
              food_name: smsl.foodName,
              food: smsl.food,
              waste_type: smsl.wasteType,
              course: smsl.course,
              waste_grams: 0,
              prepared_amount_grams: 0,
              consumption_grams: 0
            }
          })
        })
        servings.push({
          date: targetDays[weekdayIndex],
          servings: servingArr
        })
      })
      postWeekMenu(servings)
    }
  }

  const copyWeekModal = (
    <Modal
      isActive={copyWeekModalOpen}
      hideModalSubmit={
        copyWeekProcessing || sourceYear === false || sourceWeek === false || targetYear === false || targetWeek === false || (sourceYear === targetYear && sourceWeek.value === targetWeek.value)
      }
      title={t('Kopioi viikon ruokalista')}
      closeModalCallBack={() => {
        setCopyWeekModalOpen(false)
        setSourceYear(false)
        setSourceWeek(false)
        setTargetYear(false)
        setTargetWeek(false)
      }}
      submitCallback={() => {
        setCopyWeekProcessing(true)
        handleCopyWeekModalSubmit()
      }}
    >
      {copyWeekProcessing && (
        <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '50px', height: '1500px'}}>
          {t('Ladataan')}...<br /><br />
          {t('Kopioidaan ruokalistat viikosta')} {sourceWeek.value}/{sourceYear}<br />
          {t('viikkoon')} {targetWeek.value}/{targetYear}
        </p>
      )}
      {!copyWeekProcessing && (<>
        <Modal.Field label={t('Kopioidaan ruokalistat viikosta')}>
          <div>
            <span
              className="is-size-4 fas fa-chevron-left"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setSourceYear(sourceYear - 1)
                setSourceWeek(false)
              }}
            />
            <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {sourceYear}</span>
            <span
              className="is-size-4 fas fa-chevron-right"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setSourceYear(sourceYear + 1)
                setSourceWeek(false)
              }}
            />
            <HukkaSelect
              placeholder={t('Valitse')}
              options={weeksInYear(sourceYear)}
              value={sourceWeek}
              onChange={e => setSourceWeek(e)}
            />
          </div>
        </Modal.Field>

        <Modal.Field label={t('viikkoon')}>
          <div>
            <span
              className="is-size-4 fas fa-chevron-left"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setTargetYear(targetYear - 1)
                setTargetWeek(false)
              }}
            />
            <span className="is-size-4" style={{margin: '0 10px'}}>{t('Vuosi')} {targetYear}</span>
            <span
              className="is-size-4 fas fa-chevron-right"
              style={{cursor: 'pointer'}}
              onClick={() => {
                setTargetYear(targetYear + 1)
                setTargetWeek(false)
              }}
            />
            <HukkaSelect
              placeholder={t('Valitse')}
              options={weeksInYear(targetYear)}
              value={targetWeek}
              onChange={e => setTargetWeek(e)}
            />
          </div>
        </Modal.Field>
      </>)}
    </Modal>
  )


  const textModal = (
    <Modal
      isActive={textModalOpen}
      okButtonOnly
      title={t('Viikon ruokalistat')}
      closeModalCallBack={() => {
        setTextModalOpen(false)
      }}
    >
      <button
        className="button is-green"
        onClick={() => {
          const node = document.getElementById('menuAsText');

          if (document.body.createTextRange) {
              const range = document.body.createTextRange();
              range.moveToElementText(node);
              range.select();
          } else if (window.getSelection) {
              const selection = window.getSelection();
              const range = document.createRange();
              range.selectNodeContents(node);
              selection.removeAllRanges();
              selection.addRange(range);
          }
        }}
      >
        <Trans>Valitse teksti kopiointia varten</Trans>
      </button>
      <div id="menuAsText" style={{textAlign: 'left'}}>
        {menuAsText()}
      </div>
    </Modal>
  )

  return (
    <div>
      <h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Ruokalista</Trans></h1>

      {errors && (
        <div style={{margin: '10px 0'}}>
          <ErrorMessage msg={errors} />
        </div>
      )}

      {restaurants && restaurants.length > 0 && restaurantSelect}

      {selectedRestaurants && selectedRestaurants.length > 0 && restaurants && (
        <div style={{ marginTop: '3rem' }}>
          <div className="is-size-3">
            {selectedRestaurants.map(r => r.label).join(', ')}
          </div>
          <div style={{margin: '10px 0'}} className="is-size-3">
            <b><Trans>Viikko</Trans> {weekNumber}</b>
          </div>
          <button
            className="button"
            onClick={() => {
              setTextModalOpen(true)
            }}
          >
            <Trans>Viikon ruokalista tekstimuodossa</Trans>
          </button>
          <br /><br />
          <button
            className="button is-green"
            disabled={fetchedServingsMon !== moment(monday).format('YYYY-MM-DD')}
            onClick={() => {
              let year = yearNumber
              setSourceYear(year)
              setSourceWeek({value: weekNumber, label: t('Viikko') + ' ' + weekNumber})
              setTargetYear(moment().year())
              changeWeekNumber(0)
              setCopyWeekModalOpen(true)
            }}
          >
            <Trans>Kopioi viikon ruokalista</Trans>
          </button>
          {copyWeekSuccess && (
            <div style={{marginTop: '10px'}}>
              <SuccessMessage msg={copyWeekSuccess} />
            </div>
          )}
          {copyWeekError && (
            <div style={{marginTop: '10px'}}>
              <ErrorMessage msg={copyWeekError} />
            </div>
          )}
          {weekNavigator}
          {fetchedServingsMon !== moment(monday).format('YYYY-MM-DD') && (
            <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '50px', height: '1500px'}}>Ladataan...</p>
          )}
          {fetchedServingsMon === moment(monday).format('YYYY-MM-DD') && (
            <div className="menu-rows">{dateRows()}</div>
          )}
          {copyWeekModal}
          {textModal}
        </div>
      )}
    </div>
  )
}

export default Menu
